
import { mapGetters } from "vuex";
import { contentLink } from "@/utils";
import contentLinkMixin from "~/mixins/contentLink";
import { fileTypes } from "@/config";
import UiLazyVideo from "@/components/UI/UiLazyVideo.vue";
export default {
  components: {
    UiLazyVideo,
  },
  mixins: [contentLinkMixin],
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    blockIndex: {
      type: Number,
      default: -1,
    },
  },
  data() {
    return {
      fileTypes,
    };
  },
  computed: {
    ...mapGetters({
      appBreakpoints: "general/getAppBreakpoints",
    }),
    currentSlide() {
      return this.data?.slides?.[0] || null;
    },
    fileType() {
      if (this.currentSlide?.file?.media_type?.includes(fileTypes.video)) {
        return fileTypes.video;
      }
      return fileTypes.image;
    },
  },
};
